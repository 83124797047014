



















import { FileAttachmentType, GetFileModel } from 'truemarket-modules/src/models/api/files'
import { Services } from 'truemarket-modules/src/services'
import { Vue, Component, Prop } from 'vue-property-decorator'

@Component
export default class ImageGallery extends Vue {
  @Prop({ required: true })
  private readonly objectId!: string;

  @Prop({ required: true })
  private readonly objectType!: string;

  files: GetFileModel[] = [];

  uploading = false;

  mounted () {
    this.updateFiles()
  }

  updateFiles () {
    // Services.API.Files.GetForObject(this.objectId, this.objectType).then((files) => {
    //   this.files = files
    // })
  }

  upload () {
    const fi = document.createElement('input') as HTMLInputElement

    fi.type = 'file'

    fi.onchange = () => {
      const file = fi.files![0]

      this.uploading = true

      if (file) {
      }
    }

    fi.click()
  }

  // detach (file: GetFileModel) {
  //   Services.API.Files.Detach(file.FileId, this.objectId, this.objectType, FileAttachmentType.ImageGalleryItem).then(() => {
  //     this.updateFiles()
  //   })
  // }
}
