
































import { FormField } from '@/app_code/Forms'
import Copyable from '@/components/Controls/Copyable.vue'
import ImageGallery from '@/components/Controls/ImageGallery.vue'
import Form from '@/components/UI/Elements/Forms/Form.vue'
import { BlogModel, BlogPostStatus, UpdateBlogModel } from 'truemarket-modules/src/models/api/blogs'
import { Services } from 'truemarket-modules/src/services'
import { Component, Vue } from 'vue-property-decorator'

@Component({
  components: {
    Form,
    ImageGallery,
    Copyable
  }
})
export default class EditPost extends Vue {
  private fields: FormField[] = []

  private blogId = ''

  private blog: BlogModel | null = null

  mounted () {
    this.routeUpdated()
  }

  savePost () {
    if (this.blog === null) return

    const model = (this.$refs.form as Form).GetValues().BuildModel<UpdateBlogModel>()

    model.Status = this.blog.Status

    Services.API.Blogs.UpdateBlog(this.blog.BlogId, model)
  }

  publishPost () {
    if (this.blog === null) return

    this.blog.Status = BlogPostStatus.Published

    this.savePost()
  }

  routeUpdated () {
    this.blogId = this.$route.params.id

    if (!this.blogId) {
      this.$router.push('/dashboard/blogs')
      return
    }

    Services.API.Blogs.GetBlog(this.blogId).then((blog) => {
      this.blog = blog

      console.log(blog)

      this.fields = [
        new FormField({
          Key: 'ExpirationDate',
          Type: 'date',
          Label: 'Expiration Date (optional)',
          Value: blog.ExpirationDate?.toISOString() ?? ''
        }),
        new FormField({
          Key: 'Heading',
          Type: 'text',
          Label: 'Heading',
          Placeholder: '',
          Value: blog.Heading
        }),
        new FormField({
          Key: 'Subheading',
          Label: 'Subheading',
          Type: 'text',
          Value: blog.Subheading
        }),
        new FormField({
          Key: 'Content',
          Label: 'Content',
          Type: 'richtext',
          Value: blog.Content
        }),
        new FormField({
          Key: 'ExternalLink1',
          Type: 'url',
          Label: 'External Link 1',
          Value: blog.ExternalLinks[0] ?? ''
        }),
        new FormField({
          Key: 'ExternalLink2',
          Type: 'url',
          Label: 'External Link 2',
          Value: blog.ExternalLinks[1] ?? ''
        })
      ]
    })
  }
}
